import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../change-country-language'
import Menu from '../../molecules/menu'
import Link from '../../molecules/link'
import { NavMenu } from '../../molecules/menu/menu-first-level.styled'
import Typography from '../../atoms/typography'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })``

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(3, 2)};
	.mini-trigger {
		padding: 0;
		svg {
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		}
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
`

const LogoWrapper = styled('div')`
	margin: 0 auto;
	max-width: 166px;
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		max-width: 237px;
	}

	svg {
		max-width: 100%;
	}
`

const MenuStyled = styled(Menu)`
	padding: ${({ theme }) => theme.spacing(0.5, 0, 1.5)};
	${NavMenu} {
		grid-gap: ${({ theme }) => theme.spacing(4.75)};
		justify-content: center;
	}
`

const Label = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
	font-family: var(--font-base-medium);
	font-weight: 500;
`

const LinkWrapper = styled(Link)`
	display: flex;
	align-items: center;
	gap: 10px;

	.MuiSvgIcon-root {
		width: 16px;
		height: 16px;
	}
`

export { ChangeCountryLanguageStyled, GridContainer, Header, LogoWrapper, MenuStyled, Label, LinkWrapper }
